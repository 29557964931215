import { useMonaco } from '../../spec/use-monaco';
import { default as Editor } from '@monaco-editor/react';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { editor } from 'monaco-editor';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { monacoOptions } from './monaco.utils';
import {
  Button,
  cn,
  EditorControlsToolbar,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useEditorControlsToolbar,
} from '@xspecs/design-system';
import { convertJsonToSchema, convertSchemaToJson, safeParseJson } from './json-editor.utils';
import { useIntl } from 'react-intl';
import { jsonrepair } from 'jsonrepair';
import { CommandStrategy, Status } from '@xspecs/single-source-model';
import { Loading } from '../../loading/loading';
import { ArrowLeft, ArrowLeftRight, ArrowRight, Braces, Table, WandSparkles } from 'lucide-react';
import { SchemaEditor, SchemaEditorRef } from './schema-editor';

type SchemaEditorProps = {
  id: string;
  onEditorChange?: (value: string) => void;
  showJsonRepresentation?: boolean;
  initialCommandStrategy?: CommandStrategy;
  showCommandStrategy?: boolean;
  initialValue?: string;
  schemeDefaultValue?: string;
};

export const JsonEditor = (props: SchemaEditorProps) => {
  const {
    id,
    onEditorChange,
    showJsonRepresentation = false,
    initialCommandStrategy,
    showCommandStrategy = false,
    initialValue = '{}',
    schemeDefaultValue = '{}',
  } = props;

  const { formatMessage: f } = useIntl();

  const [structure, setStructure] = useState('');
  const [commandStrategy, setCommandStrategy] = useState<CommandStrategy>();
  const schemaEditorRef = useRef<SchemaEditorRef>(null);
  const { activeLayout, onToggleLayout } = useEditorControlsToolbar();

  const jsonEditorRef = useRef<JsonEditorInnerRef>(null);

  const options = useMemo<editor.IStandaloneEditorConstructionOptions>(
    () => ({
      ...monacoOptions,
      language: 'json',
      theme: 'vs-dark',
    }),
    [],
  );

  useEffect(() => {
    if (initialCommandStrategy) {
      setCommandStrategy(initialCommandStrategy);
    }
  }, [initialCommandStrategy]);

  if (!showJsonRepresentation) {
    return <JsonEditorInner options={options} id={id} />;
  }

  const normalizeDataValue = () => {
    jsonEditorRef.current?.normalizeValue(jsonrepair);
  };

  const normalizeSchemaValue = () => {
    schemaEditorRef.current?.normalizeValue(jsonrepair);
  };

  const onArrowLeft = () => {
    if (!schemaEditorRef.current || !jsonEditorRef.current) return;

    const schemaValue = schemaEditorRef.current.getEditorValue();
    const existingValueRaw = jsonEditorRef.current.getEditorValue();

    const modify = (temp: string) => {
      return convertSchemaToJson(safeParseJson(temp));
    };

    const generatedJson = modify(schemaValue);
    const existingJson = safeParseJson(existingValueRaw || '{}');

    function mergeMissingFields(source: any, target: any) {
      for (const key in source) {
        if (!(key in target)) {
          target[key] = source[key];
        } else if (
          typeof source[key] === 'object' &&
          source[key] !== null &&
          !Array.isArray(source[key]) &&
          typeof target[key] === 'object' &&
          target[key] !== null &&
          !Array.isArray(target[key])
        ) {
          mergeMissingFields(source[key], target[key]);
        }
      }
    }

    mergeMissingFields(generatedJson, existingJson);
    const updatedJsonStr = showJsonRepresentation ? JSON.stringify(existingJson, null, 2) : schemaValue;
    jsonEditorRef.current.setEditorValue(updatedJsonStr);
  };
  const onArrowRight = () => {
    if (!schemaEditorRef.current || !jsonEditorRef.current) return;

    const jsonEditorValue = jsonEditorRef.current.getEditorValue();
    const newSchema = convertJsonToSchema(safeParseJson(jsonEditorValue));

    let existingSchema: any;
    try {
      existingSchema = safeParseJson(schemaEditorRef.current.getEditorValue() || '{}');
    } catch {
      existingSchema = {
        name: '',
        type: 'object',
        fields: [],
      };
    }

    function mergeSchema(source: any, target: any): any {
      const merged = {
        name: target.name || source.name,
        type: target.type || source.type,
        fields: Array.isArray(target.fields) ? [...target.fields] : [],
      };

      for (const sourceField of source.fields) {
        const existingField = merged.fields.find((f) => f.name === sourceField.name);

        if (!existingField) {
          merged.fields.push(sourceField); // Add missing field
        } else if (
          sourceField.type === 'object' &&
          existingField.type === 'object' &&
          sourceField.fields &&
          existingField.fields
        ) {
          existingField.fields = mergeSchema(
            { ...sourceField, name: '', type: '', fields: sourceField.fields },
            { ...existingField, name: '', type: '', fields: existingField.fields },
          ).fields;
        }
      }

      return merged;
    }

    const mergedSchema = mergeSchema(newSchema, existingSchema);

    const structure = showJsonRepresentation ? JSON.stringify(mergedSchema, null, 2) : jsonEditorValue;

    schemaEditorRef.current.setEditorValue(structure);
  };
  const onArrowLeftRight = () => {
    onArrowLeft();
    onArrowRight();
  };

  const Left = (
    <div key={`${id}Left${activeLayout}`} className="size-full flex flex-col">
      <div className="flex items-center justify-between px-1 py-2 h-[45px]">
        <div className="flex gap-1 text-primary text-sm items-center">
          <Table />
          <p className="">{f({ id: 'data' })}</p>
        </div>
        <Tooltip>
          <TooltipTrigger>
            <Button variant="secondary" className="w-8 h-8" onClick={normalizeDataValue}>
              <WandSparkles />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Normalize JSON</TooltipContent>
        </Tooltip>
      </div>
      <JsonEditorInner ref={jsonEditorRef} options={options} id={id} initialValue={initialValue} />
    </div>
  );

  const Right = (
    <div key={`${id}Right${activeLayout}`} className="size-full flex flex-col">
      <div className="flex items-center justify-between px-1 py-2 h-[45px]">
        <div className="flex gap-1 text-primary text-sm items-center">
          <Braces />
          <p className="">{f({ id: 'schema' })}</p>
        </div>
        <Tooltip>
          <TooltipTrigger>
            <Button variant="secondary" className="w-8 h-8" onClick={normalizeSchemaValue}>
              <WandSparkles />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Normalize JSON</TooltipContent>
        </Tooltip>
      </div>
      <div className="grow-1">
        <SchemaEditor ref={schemaEditorRef} id={id.replace('json', '')} defaultValue={schemeDefaultValue} />
      </div>
    </div>
  );

  const Middle = (
    <div key={`${id}Middle${activeLayout}`} className="flex flex-col gap-2 items-center justify-center w-10">
      <Tooltip disableHoverableContent>
        <TooltipTrigger>
          <Button size="icon" variant="ghost" onClick={onArrowLeft}>
            <ArrowLeft />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Infer schema from data</TooltipContent>
      </Tooltip>
      <Tooltip disableHoverableContent>
        <TooltipTrigger>
          <Button size="icon" variant="ghost" onClick={onArrowRight}>
            <ArrowRight />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Infer data from schema</TooltipContent>
      </Tooltip>
      <Tooltip disableHoverableContent>
        <TooltipTrigger>
          <Button size="icon" variant="ghost" onClick={onArrowLeftRight}>
            <ArrowLeftRight />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Infer schema from data and data from schema</TooltipContent>
      </Tooltip>
    </div>
  );

  return (
    <div className="size-full flex flex-col">
      <EditorControlsToolbar onToggleLayout={onToggleLayout} activeLayout={activeLayout} />
      <div className="size-full flex gap-2">
        {activeLayout === 'left' || activeLayout === 'split' ? Left : null}
        {activeLayout === 'split' ? Middle : null}
        {activeLayout === 'right' || activeLayout === 'split' ? Right : null}
      </div>
    </div>
  );
};

type JsonEditorInnerProps = {
  id: string;
  options: editor.IStandaloneEditorConstructionOptions;
  initialValue?: string;
};

type JsonEditorInnerRef = {
  normalizeValue: (normalizer: (value: string) => string) => void;
  getEditorValue: () => string;
  setEditorValue: (value: string) => void;
};

const JsonEditorInner = forwardRef<JsonEditorInnerRef, JsonEditorInnerProps>(
  ({ id, options, initialValue = '{}' }, ref) => {
    const { organization } = useActiveOrganization();
    const fileId = id.includes(organization!.id) ? id : `${organization?.id}/${id}`;
    const { formatMessage: f } = useIntl();

    const { onMount, status, normalizeValue, getEditorValue, setEditorValue } = useMonaco(
      useMemo(
        () => ({
          id: fileId,
          placeholder: 'Type here...',
          initialValue,
          theme: 'vs-dark',
        }),
        [fileId, initialValue],
      ),
    );

    useImperativeHandle(
      ref,
      () => ({
        normalizeValue,
        getEditorValue,
        setEditorValue,
      }),
      [getEditorValue, normalizeValue, setEditorValue],
    );

    const isLoading = !status || [Status.Unknown, Status.Connecting, Status.Initial].includes(status);
    const isDisconnected = status === Status.Disconnected;

    const disabledClass = 'opacity-0 pointer-events-none';
    const enabledClass = 'opacity-100 pointer-events-auto';

    return (
      <div className="grow-1 relative flex flex-col items-end">
        <Editor
          language="json"
          className={cn('w-full h-full absolute', isLoading || isDisconnected ? disabledClass : enabledClass)}
          options={options}
          onMount={onMount}
          loading={<Loading />}
        />
        {isLoading && (
          <div className={cn('w-full h-full flex items-center absolute z-10 top-0', enabledClass)}>
            <Loading />
          </div>
        )}
        {isDisconnected && (
          <div className={cn('w-full h-full flex items-center justify-center absolute z-10 top-0', enabledClass)}>
            <div className="text-center">
              <p>{f({ id: 'disconnected-from-server' })}</p>
              <p>{f({ id: 'attempting-to-reconnect' })}</p>
            </div>
          </div>
        )}
      </div>
    );
  },
);
JsonEditorInner.displayName = 'JsonEditorInner';

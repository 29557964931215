import { useMutation } from '@apollo/client';
import { ChangeEvent, FormEventHandler, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { ADD_WORKSPACE_MUTATION } from '../../graphql/mutations';
import { sid } from '@xspecs/short-id';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Input,
} from '@xspecs/design-system';
import { PlusCircle } from 'lucide-react';
import { Loading } from '../../components/loading/loading';

export const CreateSpaceModal = () => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceCreationError, setWorkspaceCreationError] = useState('');

  const showCreateSpaceModal = useSingleSourceStore.use.showCreateSpaceModal();

  const { organization } = useActiveOrganization();
  const setShowCreateSpaceModal = useSingleSourceStore.use.setShowCreateSpaceModal();

  const { trackEvent } = useTrackEvents();

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const [addWorkspace, { client, loading }] = useMutation(ADD_WORKSPACE_MUTATION);

  const onWorkspaceNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setWorkspaceName(event.target.value);
    setWorkspaceCreationError('');
  }, []);

  const onCreateWorkspace = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      if (!organization) return;
      e.preventDefault();
      const workspaceId = sid();
      const response = await addWorkspace({
        variables: { args: { organizationId: organization?.id, workspaceId, workspaceName } },
      });
      const responseError = response.data?.addWorkspace?.error;
      if (responseError) {
        setWorkspaceCreationError(responseError);
      } else {
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'organizations' });
        trackEvent(AppTypeEvent.WorkspaceCreated, { workspaceId, workspaceName, organizationId: organization?.id });
        setShowCreateSpaceModal(false);
        navigate(
          getRoutePathFromParams(RoutePaths.Space, {
            organizationName: organization?.name,
            workspaceName,
            fileId: '',
          }),
        );
      }
    },
    [organization, addWorkspace, workspaceName, client.cache, trackEvent, setShowCreateSpaceModal, navigate],
  );

  const onCancelClick = useCallback(() => {
    setShowCreateSpaceModal(false);
  }, [setShowCreateSpaceModal]);

  return (
    <Dialog open={showCreateSpaceModal} onOpenChange={setShowCreateSpaceModal}>
      <DialogContent className="w-full max-w-lg p-6 rounded-lg">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-lg font-bold">
            <PlusCircle size={20} /> {f({ id: 'create-new-space' })}
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>{f({ id: 'your-new-space-needs-a-name' })}</DialogDescription>
        <div>
          <form onSubmit={onCreateWorkspace} className="space-y-4">
            <div className="flex flex-col gap-2">
              <label className="text-sm font-medium text-gray-700">{f({ id: 'space-name' })}</label>
              <Input
                autoFocus
                placeholder={f({ id: 'space-name' })}
                onChange={onWorkspaceNameChange}
                className="mt-1"
              />
              {workspaceCreationError && (
                <p className="text-red-500 text-sm mt-1">{f({ id: workspaceCreationError })}</p>
              )}
            </div>
            <div className="flex justify-end gap-4">
              <Button type="button" variant="ghost" onClick={onCancelClick}>
                {f({ id: 'cancel' })}
              </Button>
              <Button className="w-40" type="submit" variant="default" disabled={!workspaceName} isLoading={loading}>
                {loading ? <Loading /> : f({ id: 'create-this-space' })}
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

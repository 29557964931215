import { useCallback, useEffect, useRef, useState } from 'react';
import { OnMount } from '@monaco-editor/react';
import { MonacoBinding } from 'y-monaco';
import { PlaceholderContentWidget } from './placeholder-content-widget';
import { useApplication } from '../../wrappers/application-context/application-context';
import { singleSourceStore, useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { FileType, LoadFileCommand, SpecFile, Status } from '@xspecs/single-source-model';
import { logger } from '@xspecs/logger';
import { editor } from 'monaco-editor';
import { useWindowEvent } from '@mantine/hooks';
import { useCommandDispatch } from '@xspecs/design-system';

const MONACO_EDITOR_VIEW_STATE_KEY = 'monaco-editor-view-state';

export const useMonaco = ({
  id,
  placeholder,
  initialValue = '',
  valueNormalizer,
  onBlur,
  theme,
  defaultValue = '',
}: {
  id: string;
  placeholder: string;
  initialValue?: string;
  valueNormalizer?: (value: string) => string;
  onBlur?: (value: string) => void;
  theme?: string;
  defaultValue?: string;
}) => {
  const { application } = useApplication();
  const { dispatchCommand } = useCommandDispatch();

  const [editor, setEditor] = useState<editor.IStandaloneCodeEditor | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const isFirstSyncRef = useRef(true);
  const fileId = id;
  const files = useSingleSourceStore.use.filesById();
  const file = files[fileId];

  const onMount = useCallback<OnMount>(
    (editor, monaco) => {
      if (theme) monaco.editor.setTheme(theme);

      application?.context.messageBus.sendInternal(LoadFileCommand, {
        fileId,
        version: undefined,
        fileType: FileType.Spec,
        fileExtension: 'spec',
      });

      editor.onDidChangeModelContent(() => {
        if (isFirstSyncRef.current && onBlur) onBlur(editor.getValue());
      });

      editor.onDidBlurEditorText(() => {
        if (valueNormalizer) editor.setValue(valueNormalizer(editor.getValue()));
        if (onBlur) onBlur(editor.getValue());
        if (defaultValue) {
          try {
            const current = editor.getValue();
            const parsedValue = JSON.parse(current || '{}');
            const parsedDefaultValue = JSON.parse(defaultValue || '{}');
            const missingKeys = Object.keys(parsedDefaultValue).filter((key) => !(key in parsedValue));
            const toAdd = missingKeys.reduce((acc, key) => {
              acc[key] = parsedDefaultValue[key];
              return acc;
            }, parsedValue);
            editor.setValue(JSON.stringify(toAdd, null, 2));
          } catch (error) {
            // logger.error('Error parsing value:', error);
          }
        }
      });

      const file = singleSourceStore.getState().filesById[fileId].file as SpecFile;
      new PlaceholderContentWidget(placeholder, editor);
      const ytext = file.sharedType;
      const model = editor.getModel();
      if (!model) {
        logger.error('Editor model not found');
        return;
      }
      const binding = new MonacoBinding(ytext, model, new Set([editor]), file.awareness);

      editor.onDidDispose(() => {
        dispatchCommand('DisconnectFileCommand', {
          fileId,
          version: undefined,
          fileType: FileType.Spec,
          fileExtension: 'spec',
        });
      });
      setEditor(editor);

      setTimeout(() => {
        monaco.editor.remeasureFonts();
      }, 300);
    },
    [
      application?.context.messageBus,
      defaultValue,
      dispatchCommand,
      fileId,
      onBlur,
      placeholder,
      theme,
      valueNormalizer,
    ],
  );

  const normalizeValue = useCallback(
    (normalizer: (value: string) => string) => {
      if (!editor) return;
      editor.setValue(normalizer(editor.getValue()));
    },
    [editor],
  );

  const setEditorValue = useCallback(
    (value: string) => {
      if (!editor) return;
      editor.setValue(value);
    },
    [editor],
  );

  const getEditorValue = useCallback(() => {
    if (!editor) return '';
    return editor.getValue();
  }, [editor]);

  useEffect(() => {
    if (editor && file?.status === Status.Synced && (initialValue || defaultValue)) {
      if (!editor.getModel()?.getValue()) {
        editor.getModel()?.setValue(defaultValue ?? initialValue);
      }
    }
  }, [file?.status, editor, initialValue, defaultValue]);

  const onUnmount = useCallback((id: string, editor: editor.IStandaloneCodeEditor) => {
    const state = editor?.saveViewState();
    if (!state) return;
    const current = localStorage.getItem(MONACO_EDITOR_VIEW_STATE_KEY) || '{}';
    const viewStates = JSON.parse(current);
    viewStates[id] = state;
    localStorage.setItem(MONACO_EDITOR_VIEW_STATE_KEY, JSON.stringify(viewStates));
  }, []);

  useEffect(() => {
    if (file?.status === Status.Synced && !isConnected) {
      setIsConnected(true);
      isFirstSyncRef.current = true;
    }
  }, [file?.status, isConnected]);

  useEffect(() => {
    if (!editor || !isConnected) return;

    const current = localStorage.getItem(MONACO_EDITOR_VIEW_STATE_KEY) || '{}';
    const viewStates = JSON.parse(current);
    const state = viewStates[id];
    if (state) {
      editor.restoreViewState(state);
    }

    return () => {
      if (!isConnected || !editor) return;
      const state = editor.saveViewState();
      const current = localStorage.getItem(MONACO_EDITOR_VIEW_STATE_KEY) || '{}';
      const viewStates = JSON.parse(current);
      viewStates[id] = state;
      localStorage.setItem(MONACO_EDITOR_VIEW_STATE_KEY, JSON.stringify(viewStates));
    };
  }, [editor, id, isConnected]);

  useWindowEvent('beforeunload', () => {
    if (editor && isConnected) {
      onUnmount(id, editor);
    }
  });

  return {
    onMount,
    status: file?.status,
    normalizeValue,
    setEditorValue,
    getEditorValue,
  } as const;
};

const getDefaultValue = (defaultValue: string = '') => {
  try {
    const parsedValue = JSON.parse(defaultValue || '{}');
    return parsedValue;
  } catch {
    return '';
  }
};

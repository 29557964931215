import { EntityDescription } from '../../entity/entity-description/entity-description';
import { CommandStrategy, EntityDetailsEditor } from '@xspecs/single-source-model';

type FloatingDetailsViewProps = {
  entity: {
    id: string;
    name: string;
    commandStrategy?: CommandStrategy;
    editor: EntityDetailsEditor;
    type: string;
    schemeDefaultValue?: string;
  };
};

export const FloatingDetailsView = (props: FloatingDetailsViewProps) => {
  const { entity } = props;

  return (
    <div className="react-flow__node-toolbar shadow-md bg-background-grey rounded-lg size-full overflow-y-scroll">
      <EntityDescription
        entityId={entity.id}
        editorType={entity.editor}
        schemas={{
          commands: {},
          events: {},
          states: {},
        }}
        name={entity.name}
        commandStrategy={entity.commandStrategy}
        entityType={entity.type}
        schemeDefaultValue={entity.schemeDefaultValue}
      />
    </div>
  );
};

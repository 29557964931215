import { NodeProps, NodeTypes } from '@xyflow/react';
import { ComponentType } from 'react';

export const wrapNodesWithHoc = (
  nodes: NodeTypes,
  hoc: (node: ComponentType<NodeProps>) => ComponentType<NodeProps>,
  keysToExclude: string[] = [],
) =>
  Object.keys(nodes).reduce((acc, key) => {
    acc[key] = keysToExclude.includes(key) ? nodes[key] : hoc(nodes[key]);
    return acc;
  }, {} as NodeTypes);

const FIGMA_URL_REGEX = /https:\/\/[\w.-]+\.?figma.com\/([\w-]+)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;

export const isValidFigmaEmbedUrl = (url: string) => {
  return FIGMA_URL_REGEX.test(url);
};

export const formatFigmaUrlForEmbed = (url: string) => {
  const host = window.location.hostname;

  return url
    .replace(/(www\.)?(figma\.com)/, 'embed.figma.com')
    .concat(url.includes('?') ? '&' : '?', `embed-host=${host}`);
};

export const extractFigmaFileName = (url: string) => {
  const match = url.match(/\/([^/?]+)(?:\?|$)/);
  if (match) {
    const decoded = decodeURIComponent(match[1]);
    return decoded.replace(/-/g, ' ');
  }
  return null;
};

import { useState } from 'react';
import { EditorControlsToolbarView } from '@xspecs/design-system';

export const useEditorControlsToolbar = () => {
  const [activeLayout, setActiveLayout] = useState<EditorControlsToolbarView>('split');

  const onToggleLayout = (value: EditorControlsToolbarView) => {
    setActiveLayout(value);
  };

  return {
    activeLayout,
    onToggleLayout,
  } as const;
};

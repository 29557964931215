import { Ghost, Plus } from 'lucide-react';
import { Button } from '@xspecs/design-system';
import { SidebarNewFileDropdown, SidebarNewFileDropdownProps } from './new-file-dropdown';
import { FC } from 'react';
import { useIntl } from 'react-intl';

export type NoFilesResultsProps = {
  addMenuItems: SidebarNewFileDropdownProps['options'];
};

export const NoFilesResults: FC<NoFilesResultsProps> = (props) => {
  const { addMenuItems } = props;
  const { formatMessage: f } = useIntl();

  return (
    <div className="p-2 flex flex-col gap-2 items-center justify-center">
      <Ghost className="text-muted-foreground" size={16} />
      <span className="text-primary text-xs font-medium">{f({ id: 'nothing-added-yet' })}</span>
      <span className="text-secondary text-xs text-center">{f({ id: 'if-you-want-to-create-something' })}</span>
      <SidebarNewFileDropdown
        options={addMenuItems}
        trigger={
          <Button size="sm">
            <Plus size="16" /> Create new file
          </Button>
        }
        side="bottom"
        align="center"
      />
    </div>
  );
};

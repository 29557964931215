import { ScriptBase } from '../../entities/scripts/ScriptBase';
import {
  CommonNodeData,
  Frame as FrameLayout,
  FrameGroup as FrameGroupLayout,
  HeaderProps,
  InsertHandlers,
  LabelProps,
  Lane as LaneLayout,
  LaneGroup as LaneGroupLayout,
  ScriptNodeData,
} from './Graph.types';
import {
  AllowedAction,
  FrameGroup as FrameGroupConfig,
  LaneGroup as LaneGroupConfig,
  ViewMode,
} from 'narrative-studio-sdk';
import { ScriptConfig } from '../../entities/scripts/ScriptConfig';

export class ScriptToScriptNodeTranslator {
  public static translate(
    script: ScriptBase,
    commonNodeData: CommonNodeData,
    devMode: boolean = false,
    isDarkTheme: boolean = false,
  ): ScriptNodeData {
    return {
      ...commonNodeData,
      width: script.width,
      height: devMode ? script.height : (script.attributes.metadata?.nonDevMode?.height ?? script.height),
      labels: script.labels,
      attributes: { fontSize: script.attributes.fontSize, suggested: script.attributes.suggested },
      frameGroups: this.getFrameGroups(script, devMode, isDarkTheme),
      laneGroups: this.getLaneGroups(script, devMode, isDarkTheme),
    };
  }

  private static getLaneGroups(script: ScriptBase, devMode: boolean, isDarkTheme: boolean): LaneGroupLayout[] {
    let cumulativeLaneGroupY = 0;
    return script?.laneGroups
      .filter((laneGroup) => {
        const laneGroupConfig = script.config.laneGroups[laneGroup.configIndex];
        return script.filterForDevMode(laneGroupConfig, devMode);
      })
      ?.map((laneGroup) => {
        const laneGroupConfig = script.config.laneGroups[laneGroup.configIndex];
        let cumulativeLaneY = 0;
        const laneGroupY = cumulativeLaneGroupY;
        const lanes = laneGroup.laneIds.map((laneId, laneIndex) => {
          const lane = script.lanes.find((l) => l.id === laneId);
          const laneHeight = lane?.height ?? laneGroupConfig.defaultLaneHeight ?? script.config.defaultLaneHeight;
          const laneStyle =
            laneGroupConfig?.lanes?.[laneIndex]?.[isDarkTheme ? 'darkModeStyle' : 'style'] ??
            laneGroupConfig?.lanes?.[0]?.[isDarkTheme ? 'darkModeStyle' : 'style'];

          const laneAlignmentFrameIndex = laneGroupConfig?.laneAlignmentFrameIndex ?? 0;
          const frameWidth = script.config.frameGroups[0]?.defaultFrameWidth ?? script.config.defaultFrameWidth;
          const laneX = laneAlignmentFrameIndex === 0 ? 0 : frameWidth * laneAlignmentFrameIndex;

          const laneY = laneGroupY + cumulativeLaneY;
          cumulativeLaneY += laneHeight;
          return {
            x: laneX,
            y: laneY,
            width: script.width - laneX,
            height: laneHeight,
            style: {
              backgroundColor: laneStyle?.backgroundColor ?? 'white',
              borderWidth: laneStyle?.borderWidth ?? 1,
              borderColor: laneStyle?.borderColor ?? 'rgb(158, 158, 158)',
              textColor: laneStyle?.textColor ?? 'black',
            },
            headerProps: this.getLaneHeaderProps(script, laneGroupConfig, laneGroup.configIndex, laneId),
            labelProps: this.getLaneLabelProps(lane, script, laneGroupConfig, laneX, laneY, devMode, isDarkTheme),
          } satisfies LaneLayout;
        });
        const laneGroupHeight = lanes.reduce((sum, lane) => sum + lane.height, 0);
        cumulativeLaneGroupY += laneGroupHeight;
        const frameGroupConfig = script.config.frameGroups[0];
        const laneGroupWidth = frameGroupConfig?.defaultFrameWidth ?? script.config.defaultFrameWidth;
        return {
          x: 0,
          y: laneGroupY,
          width: script.width,
          height: laneGroupHeight,
          style: {
            backgroundColor: laneGroupConfig?.[isDarkTheme ? 'darkModeStyle' : 'style']?.backgroundColor ?? 'white',
            borderWidth: laneGroupConfig?.[isDarkTheme ? 'darkModeStyle' : 'style']?.borderWidth ?? 1,
            borderColor:
              laneGroupConfig?.[isDarkTheme ? 'darkModeStyle' : 'style']?.borderColor ?? 'rgb(158, 158, 158)',
            textColor: laneGroupConfig?.[isDarkTheme ? 'darkModeStyle' : 'style']?.textColor ?? 'black',
          },
          headerProps: {
            show: false,
          },
          lanes: lanes,
          labelProps: this.getLaneGroupLabelProps(
            laneGroupConfig,
            laneGroupWidth,
            laneGroupY,
            laneGroupHeight,
            isDarkTheme,
          ),
        } satisfies LaneGroupLayout;
      });
  }

  private static getLaneGroupLabelProps(
    laneGroupConfig: LaneGroupConfig,
    laneGroupWidth: number,
    laneGroupY: number,
    laneGroupHeight: number,
    isDarkTheme: boolean,
  ) {
    const fontSize = laneGroupConfig?.label?.fontSize ?? 24;
    const text = laneGroupConfig?.label?.text ?? '';
    const textWidth = text.length * (fontSize * 0.5);
    const textX = (laneGroupWidth - textWidth) / 2;
    const textY = laneGroupY + laneGroupHeight / 2.2;

    return {
      x: Math.max(textX, 0),
      y: textY,
      text: [
        {
          value: text,
          style: {
            fontSize,
            fontWeight: 500,
            color: laneGroupConfig?.[isDarkTheme ? 'darkModeStyle' : 'style']?.textColor,
          },
        },
      ],
    };
  }

  private static getLaneLabelProps(
    lane: any,
    script: ScriptBase,
    laneGroupConfig: LaneGroupConfig,
    laneX: number,
    laneY: number,
    devMode: boolean,
    isDarkTheme: boolean,
  ): LabelProps | undefined {
    const visibleInModes = laneGroupConfig?.label?.visibleInModes ?? ViewMode.ALL;
    if (visibleInModes === ViewMode.DEV && !devMode) return undefined;
    const showLabel =
      ScriptConfig.hasLaneGroupPermission(laneGroupConfig, AllowedAction.UPDATE) || lane?.label?.text?.length > 0;

    if (!showLabel) {
      return undefined;
    }
    return {
      text: [
        {
          value: lane?.label ?? '',
          style: {
            fontSize: 24,
            fontWeight: 500,
            color: laneGroupConfig[isDarkTheme ? 'darkModeStyle' : 'style']?.textColor,
          },
          onRename: {
            command: 'RenameScriptLaneCommand',
            params: { scriptId: script.id, laneId: lane?.id ?? '', value: '' },
          },
        },
      ],
      x: laneX + 10,
      y: laneY + 10,
    } satisfies LabelProps;
  }

  private static getFrameGroups(script: ScriptBase, devMode: boolean, isDarkTheme: boolean): FrameGroupLayout[] {
    let cumulativeFrameGroupX = 0;

    return script.frameGroups.map((frameGroup, frameGroupIndex) => {
      const frameGroupConfig = script.config.frameGroups[frameGroup.configIndex];

      const frameGroupX = cumulativeFrameGroupX;
      let cumulativeFrameX = 0;

      const frames = frameGroup.frameIds
        .map((frameId) => script.frames.find((f) => f.id === frameId))
        .filter((frame): frame is NonNullable<typeof frame> => frame !== undefined)
        .map((frame, frameIndex) => {
          if (!frame) {
            throw new Error(`Frame with id ${frameGroup.frameIds[frameIndex]} not found`);
          }

          const frameStyle =
            frameGroupConfig?.frames?.[frameIndex]?.[isDarkTheme ? 'darkModeStyle' : 'style'] ??
            frameGroupConfig?.frames?.[0]?.[isDarkTheme ? 'darkModeStyle' : 'style'];
          const frameX = cumulativeFrameX;
          cumulativeFrameX += frame.width;

          return {
            x: frameGroupX + frameX,
            y: 0,
            width: frame.width,
            height: script.height,
            style: {
              backgroundColor: frameStyle?.backgroundColor ?? 'transparent',
              borderWidth: frameStyle?.borderWidth ?? 1,
              borderColor: frameStyle?.borderColor ?? 'rgb(158, 158, 158)',
              textColor: frameStyle?.[isDarkTheme ? 'darkModeStyle' : 'style']?.textColor ?? 'black',
            },
            headerProps: this.getFrameHeaderProps(script, frameGroupConfig, frameGroupIndex, frame.id, devMode),
          } satisfies FrameLayout;
        });

      cumulativeFrameGroupX += frames.reduce((sum, frame) => sum + frame.width, 0);
      return {
        x: frameGroupX,
        y: 0,
        width: frames.reduce((sum, frame) => sum + frame.width, 0),
        height: script.height,
        style: {
          backgroundColor:
            frameGroupConfig?.[isDarkTheme ? 'darkModeStyle' : 'style']?.backgroundColor ?? 'transparent',
          borderWidth: frameGroupConfig?.[isDarkTheme ? 'darkModeStyle' : 'style']?.borderWidth ?? 1,
          borderColor: frameGroupConfig?.[isDarkTheme ? 'darkModeStyle' : 'style']?.borderColor ?? 'rgb(158, 158, 158)',
          textColor: frameGroupConfig?.[isDarkTheme ? 'darkModeStyle' : 'style']?.textColor ?? 'rgb(158, 158, 158)',
        },
        headerProps: this.getFrameGroupHeaderProps(script, frameGroup, frameGroupConfig, frameGroupIndex),
        labelProps: this.getFrameGroupLabelProps(
          frameGroup,
          frameGroupConfig,
          frameGroupX,
          script,
          frameGroupIndex,
          devMode,
        ),
        frames: frames,
      } satisfies FrameGroupLayout;
    });
  }

  private static getFrameGroupHeaderProps(
    script: ScriptBase,
    frameGroup: { type?: string },
    frameGroupConfig: FrameGroupConfig,
    frameGroupIndex: number,
  ): HeaderProps {
    const canAddFrameGroup =
      ScriptConfig.hasFrameGroupPermission(frameGroupConfig, AllowedAction.ADD) &&
      (frameGroupConfig.frameGroupLimits.max === Infinity ||
        script.frameGroups.length < frameGroupConfig.frameGroupLimits.max);
    const configIndex = script.config.frameGroups.indexOf(frameGroupConfig);
    const matchingFrameGroupsCount = script.frameGroups.filter((fg) => fg.configIndex === configIndex).length;
    const canDeleteFrame =
      ScriptConfig.hasFrameGroupPermission(frameGroupConfig, AllowedAction.REMOVE) &&
      matchingFrameGroupsCount > (frameGroupConfig.frameGroupLimits.min ?? 1);
    const canMoveFrame = ScriptConfig.hasFrameGroupPermission(frameGroupConfig, AllowedAction.REORDER);
    const canUpdate = ScriptConfig.hasFrameGroupPermission(frameGroupConfig, AllowedAction.UPDATE);
    const hasFrameGroupMenuItems = (frameGroupConfig?.typeSelectMenu?.length ?? 0) > 0;
    let onInsertBefore: InsertHandlers['onInsertBefore'];
    let onInsertAfter: InsertHandlers['onInsertAfter'];
    if (hasFrameGroupMenuItems) {
      onInsertBefore = {
        items: frameGroupConfig.typeSelectMenu!.map((menuItem) => ({
          id: `insert-${menuItem.text.toLowerCase().replace(/\s+/g, '-')}-before`,
          label: menuItem.text,
          icon: menuItem.icon ?? 'insert-before',
          command: 'AddScriptFrameGroupCommand',
          params: {
            scriptId: script.id,
            frameGroupIndex,
            position: 'before',
            type: menuItem.type,
            menuItem,
          } as Record<string, unknown>,
        })),
      };
      onInsertAfter = {
        items: frameGroupConfig.typeSelectMenu!.map((menuItem) => ({
          id: `insert-${menuItem.text.toLowerCase().replace(/\s+/g, '-')}-after`,
          label: menuItem.text,
          icon: menuItem.icon ?? 'insert-after',
          command: 'AddScriptFrameGroupCommand',
          params: {
            scriptId: script.id,
            frameGroupIndex,
            position: 'after',
            type: menuItem.type,
            menuItem,
          } as Record<string, unknown>,
        })),
      };
    } else {
      onInsertBefore = canAddFrameGroup
        ? {
            command: 'AddScriptFrameGroupCommand',
            params: {
              scriptId: script.id,
              frameGroupIndex,
              position: 'before',
            } as Record<string, unknown>,
          }
        : undefined;

      onInsertAfter = canAddFrameGroup
        ? {
            command: 'AddScriptFrameGroupCommand',
            params: {
              scriptId: script.id,
              frameGroupIndex,
              position: 'after',
            } as Record<string, unknown>,
          }
        : undefined;
    }

    if (!canAddFrameGroup && !canDeleteFrame) {
      return {
        show: false,
      };
    }

    return {
      show: true,
      disableDrag: !canMoveFrame,
      onInsertBefore,
      onInsertAfter,
      menu: {
        items: [
          ...(canAddFrameGroup && !hasFrameGroupMenuItems
            ? [
                {
                  id: 'insert-frame-before',
                  label: 'Insert Before',
                  icon: 'insert-before',
                  command: 'AddScriptFrameGroupCommand',
                  params: {
                    scriptId: script.id,
                    frameGroupIndex,
                    position: 'before',
                  } as Record<string, unknown>,
                },
                {
                  id: 'insert-frame-after',
                  label: 'Insert After',
                  icon: 'insert-after',
                  command: 'AddScriptFrameGroupCommand',
                  params: {
                    scriptId: script.id,
                    frameGroupIndex,
                    position: 'after',
                  } as Record<string, unknown>,
                },
              ]
            : []),
          ...(canUpdate && frameGroupConfig?.typeSelectMenu
            ? frameGroupConfig.typeSelectMenu
                .filter((m) => m.type! != frameGroup.type)
                .map((menuItem) => ({
                  id: `select-${menuItem.text.toLowerCase().replace(/\s+/g, '-')}`,
                  label: `Change to "${menuItem.text}"`,
                  icon: menuItem.icon ?? 'dashed-line',
                  command: 'UpdateScriptFrameGroupCommand',
                  params: {
                    scriptId: script.id,
                    frameGroupIndex,
                    type: menuItem.type,
                  } as Record<string, unknown>,
                }))
            : []),
          ...(canDeleteFrame
            ? [
                {
                  id: 'delete-frame',
                  label: 'Delete',
                  icon: 'delete',
                  command: 'DeleteScriptFrameGroupCommand',
                  params: {
                    scriptId: script.id,
                    frameGroupIndex,
                  } as Record<string, unknown>,
                },
              ]
            : []),
        ],
      },
    } satisfies HeaderProps;
  }

  private static getFrameGroupLabelProps(
    frameGroup: { type?: string; label: string },
    frameGroupConfig: FrameGroupConfig,
    frameGroupX: number,
    script: ScriptBase,
    frameGroupIndex: number,
    devMode: boolean,
  ): LabelProps | undefined {
    const showLabel =
      ScriptConfig.hasFrameGroupPermission(frameGroupConfig, AllowedAction.UPDATE) || frameGroupConfig?.label?.text;
    if (!showLabel) {
      return undefined;
    }
    const valueSelectionMenu = frameGroupConfig.typeSelectMenu?.find((v) => v.type === frameGroup.type);
    return {
      x:
        frameGroupConfig?.label?.alignment === 'center'
          ? frameGroupX + (frameGroupConfig?.width ?? script.config.defaultFrameWidth) / 2
          : frameGroupX,
      y: -44,
      text: [
        {
          value: frameGroup.label,
          style: {
            fontSize: frameGroup.type
              ? (valueSelectionMenu?.frameGroupLabel?.fontSize ?? 12)
              : (frameGroupConfig?.label?.fontSize ?? 12),
            fontWeight: 500,
            backgroundColor: frameGroup.type
              ? (valueSelectionMenu?.frameGroupLabel?.backgroundColor ?? 'transparent')
              : (frameGroupConfig?.label?.backgroundColor ?? 'transparent'),
            color: frameGroup.type
              ? (valueSelectionMenu?.frameGroupLabel?.textColor ?? 'black')
              : (frameGroupConfig?.label?.textColor ?? 'black'),
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          },
          onRename: {
            command: 'RenameFrameGroupCommand',
            params: { scriptId: script.id, frameGroupIndex: frameGroupIndex, value: '' },
          },
        },
      ],
    } satisfies LabelProps;
  }

  private static getFrameHeaderProps(
    script: ScriptBase,
    frameGroupConfig: FrameGroupConfig,
    frameGroupIndex: number,
    frameId: string,
    devMode: boolean,
  ): HeaderProps {
    const canAddFrame =
      ScriptConfig.hasFrameGroupPermission(frameGroupConfig, AllowedAction.ADD) &&
      (frameGroupConfig.frameLimits.max === Infinity || script.frames.length < frameGroupConfig.frameLimits.max);
    const frameGroup = script.frameGroups[frameGroupIndex];
    const canDeleteFrame =
      ScriptConfig.hasFrameGroupPermission(frameGroupConfig, AllowedAction.REMOVE) &&
      frameGroup.frameIds.length > (frameGroupConfig.frameLimits.min ?? 1);
    const canMoveFrame = ScriptConfig.hasFrameGroupPermission(frameGroupConfig, AllowedAction.REORDER);
    if (!canAddFrame && !canDeleteFrame) {
      return {
        show: false,
      };
    }
    const onInsertBefore = canAddFrame
      ? {
          command: 'AddScriptFrameCommand',
          params: { scriptId: script.id, frameId, position: 'before' },
        }
      : undefined;
    const onInsertAfter = canAddFrame
      ? {
          command: 'AddScriptFrameCommand',
          params: { scriptId: script.id, frameId, position: 'after' },
        }
      : undefined;
    return {
      show: true,
      disableDrag: !canMoveFrame,
      onInsertBefore,
      onInsertAfter,
      menu: {
        items: [
          ...(canAddFrame
            ? [
                {
                  id: 'insert-frame-before',
                  label: 'Insert Before',
                  icon: 'insert-before',
                  command: onInsertBefore!.command,
                  params: onInsertBefore!.params,
                },
                {
                  id: 'insert-frame-after',
                  label: 'Insert After',
                  icon: 'insert-after',
                  ...onInsertAfter,
                  command: onInsertAfter!.command,
                  params: onInsertAfter!.params,
                },
              ]
            : []),
          ...(canDeleteFrame
            ? [
                {
                  id: 'delete-frame',
                  label: 'Delete',
                  icon: 'delete',
                  command: 'DeleteScriptFrameCommand',
                  params: { scriptId: script.id, frameId },
                },
              ]
            : []),
        ],
      },
    } satisfies HeaderProps;
  }

  private static getLaneHeaderProps(
    script: ScriptBase,
    laneGroupConfig: LaneGroupConfig,
    laneGroupIndex: number,
    laneId: string,
  ): HeaderProps {
    const canAddLane =
      ScriptConfig.hasLaneGroupPermission(laneGroupConfig, AllowedAction.ADD) &&
      (laneGroupConfig.laneLimits.max === Infinity || script.lanes.length < laneGroupConfig.laneLimits.max);
    const laneGroup = script.laneGroups[laneGroupIndex];
    const canDeleteLane =
      ScriptConfig.hasLaneGroupPermission(laneGroupConfig, AllowedAction.REMOVE) &&
      laneGroup.laneIds.length > (laneGroupConfig.laneLimits.min ?? 1);
    const canMoveLane = ScriptConfig.hasLaneGroupPermission(laneGroupConfig, AllowedAction.REORDER);
    const onInsertBefore = canAddLane
      ? {
          command: 'AddScriptLaneCommand',
          params: { scriptId: script.id, laneId, position: 'before' },
        }
      : undefined;
    const onInsertAfter = canAddLane
      ? {
          command: 'AddScriptLaneCommand',
          params: { scriptId: script.id, laneId, position: 'after' },
        }
      : undefined;
    if (!canAddLane && !canDeleteLane) {
      return {
        show: false,
        disableDrag: !canMoveLane,
      };
    }
    return {
      show: true,
      onInsertBefore,
      onInsertAfter,
      disableDrag: !canMoveLane,
      menu: {
        items: [
          ...(canAddLane
            ? [
                {
                  id: 'insert-lane-before',
                  label: 'Insert Before',
                  icon: 'insert-before',
                  command: onInsertBefore!.command,
                  params: onInsertBefore!.params,
                },
                {
                  id: 'insert-lane-after',
                  label: 'Insert After',
                  icon: 'insert-after',
                  command: onInsertAfter!.command,
                  params: onInsertAfter!.params,
                },
              ]
            : []),
          ...(canDeleteLane
            ? [
                {
                  id: 'delete-lane',
                  label: 'Delete',
                  icon: 'delete',
                  command: 'DeleteScriptLaneCommand',
                  params: { scriptId: script.id, laneId },
                },
              ]
            : []),
        ],
      },
    } satisfies HeaderProps;
  }
}

import { ToggleGroup, ToggleGroupItem } from '@xspecs/design-system';
import { AlignJustify, Image, SquareSplitHorizontal } from 'lucide-react';

export type EditorControlsToolbarView = 'left' | 'split' | 'right';

interface MarkdownToolbarProps {
  onToggleLayout: (value: EditorControlsToolbarView) => void;
  activeLayout: EditorControlsToolbarView;
}

export const EditorControlsToolbar = (props: MarkdownToolbarProps) => {
  const { onToggleLayout, activeLayout } = props;

  const onValueChange = (value: string) => {
    if (value) onToggleLayout(value as EditorControlsToolbarView);
  };

  return (
    <div className="flex items-center justify-end gap-2 px-2 py-1 border-b bg-muted">
      <ToggleGroup className="gap-2" type="single" value={activeLayout} onValueChange={onValueChange}>
        <ToggleGroupItem className="w-4 h-4" value="left">
          <AlignJustify />
        </ToggleGroupItem>
        <ToggleGroupItem className="w-4 h-4" value="split">
          <SquareSplitHorizontal />
        </ToggleGroupItem>
        <ToggleGroupItem className="w-4 h-4" value="right">
          <Image />
        </ToggleGroupItem>
      </ToggleGroup>
    </div>
  );
};

import { CloudAlert } from 'lucide-react';
import { useIntl } from 'react-intl';

export const NoConnectionState = () => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="p-2 flex flex-col gap-2 items-center justify-center">
      <CloudAlert className="text-muted-foreground" size={16} />
      <span className="text-primary text-xs font-medium">{f({ id: 'cant-connect' })}</span>
      <span className="text-secondary text-xs text-center">{f({ id: 'connection-failed-try-again' })}</span>
    </div>
  );
};

import { createTheme } from '@mui/material';

export const lightTheme = createTheme({
  palette: {
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    primary: {
      main: 'rgba(96, 106, 203, 1)',
      dark: 'rgba(66, 77, 182, 1)',
      light: 'rgba(133, 142, 223, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    secondary: {
      main: 'rgba(132, 90, 201, 1)',
      dark: 'rgba(105, 60, 180, 1)',
      light: 'rgba(164, 128, 222, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    error: {
      main: 'rgba(202, 19, 1, 1)',
      dark: 'rgba(145, 0, 0, 1)',
      light: 'rgba(255, 84, 49, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    warning: {
      main: 'rgba(237, 108, 2, 1)',
      dark: 'rgba(230, 81, 0, 1)',
      light: 'rgba(255, 152, 0, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    info: {
      main: 'rgba(54, 148, 233, 1)',
      dark: 'rgba(0, 103, 182, 1)',
      light: 'rgba(118, 196, 255, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    success: {
      main: 'rgba(46, 125, 50, 1)',
      dark: 'rgba(27, 94, 32, 1)',
      light: 'rgba(76, 175, 80, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      focus: 'rgba(0, 0, 0, 0.12)',
    },
    background: {
      paper: 'rgba(255, 255, 255, 1)',
      default: 'rgba(255, 255, 255, 1)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    other: {
      outlineBorder: '1px solid rgba(0, 0, 0, 0.23)',
      standardInputLine: '1px solid rgba(0, 0, 0, 0.42)',
      backgroundOverlay: 'rgba(0, 0, 0, 0.5)',
      ratingActive: '#FFB400',
      snackbar: '#323232',
      imagePlaceholderBg: 'rgba(238, 238, 238, 1)',
      selectedEntity: 'rgba(96, 106, 203, 0.08)',
    },
    base: {
      border: 'rgba(228, 228, 231, 1)',
      accent: 'rgba(244, 244, 245, 1)',
      primaryBackground: 'rgba(223, 225, 243, 1)',
    },
  },
  typography: {
    h1: {
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '96px',
      lineHeight: '116.7%',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '60px',
      lineHeight: '120%',
      letterSpacing: '-0.5px',
    },
    h3: { fontFamily: "'HK Grotesk'", fontStyle: 'normal', fontWeight: '400', fontSize: '48px', lineHeight: '116.7%' },
    h4: {
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '34px',
      lineHeight: '123.5%',
      letterSpacing: '0.25px',
    },
    h5: { fontFamily: "'HK Grotesk'", fontStyle: 'normal', fontWeight: '400', fontSize: '24px', lineHeight: '133.4%' },
    h6: {
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '160%',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '175%',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '157%',
      letterSpacing: '0.1px',
    },
    body1: {
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
    body2: {
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '143%',
      letterSpacing: '0.17px',
    },
    body3: {
      fontFamily: "'HK Grotesk'",
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '14px',
      letterSpacing: '0.3px',
    },
    buttonLarge: {
      display: 'block',
      width: '100%',
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '26px',
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
    },
    buttonMedium: {
      display: 'block',
      width: '100%',
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
    },
    buttonSmall: {
      display: 'block',
      width: '100%',
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '22px',
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
    },
    caption: {
      display: 'block',
      width: '100%',
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '166%',
      letterSpacing: '0.4px',
    },
    overline: {
      display: 'block',
      width: '100%',
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '266%',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    avatarLetter: {
      display: 'block',
      width: '100%',
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '20px',
      letterSpacing: '0.14px',
    },
    inputLabel: {
      display: 'block',
      width: '100%',
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '12px',
      letterSpacing: '0.15px',
    },
    helperText: {
      display: 'block',
      width: '100%',
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.4px',
    },
    inputText: {
      display: 'block',
      width: '100%',
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    tooltip: {
      display: 'block',
      width: '100%',
      fontFamily: "'HK Grotesk'",
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '10px',
      lineHeight: '14px',
    },
    stickyType: {
      display: 'block',
      textAlign: 'center',
      fontFamily: 'HK Grotesk',
      fontSize: '12px',
      fontStyle: 'italic',
      fontWeight: '300',
      lineHeight: '20px',
      letterSpacing: '0.4000000059604645px',
    },
  },
});
